import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'
import { differenceInSecondsDates } from '~/tracking/utils/time'
import { useSupportStore } from '~/store/support'
import { AVAILABLE_TO_INTERVIEWS } from '~/utils/constants/matchUserAvailable.js'

const averageAffinityOfCandidates = (candidates) => {
	if (!candidates || candidates?.length === 0) return 0

	return (
		candidates.reduce(function (total, candidate) {
			return total + candidate?.affinity
		}, 0) / candidates.length
	)
}

export default {
	talentlyListingLoaded() {
		const { previous_url } = $tracking.storage.get('app')
		const { getResource } = useSupportStore()
		const listProfiles = getResource('hire_developer_types')
		const currentQuery = document.location.search

		const { developer_type_name, paginate, old_filters, filters, start_time, end_time, candidates } =
			$tracking.storage.get('searchTools')

		if (old_filters !== undefined) return

		const listQueryProfiles = listProfiles.map((profile) => {
			const stacks = profile.skills.map((stack) => stack.id)
			return `?role_id=${profile.id}&stacks=[${stacks}]&min_salary=700`
		})

		const isDefaultSearch = listQueryProfiles.includes(currentQuery)

		const availableCandidates = candidates.reduce(function (total, candidate) {
			return candidate?.availability?.id === AVAILABLE_TO_INTERVIEWS ? total + 1 : total
		}, 0)

		const params = {
			affinity_average: averageAffinityOfCandidates(candidates),
			available_talents: availableCandidates,
			default_search: isDefaultSearch,
			developer_type: developer_type_name,
			filters,
			loading_time: differenceInSecondsDates(start_time, end_time),
			total_results: paginate?.total,
			previous_url: previous_url || '',
		}

		tracer.track('talently_listing_loaded', params)
	},

	filterClearClicked() {
		const { filters } = $tracking.storage.get('searchTools')

		const params = {
			old_filters: filters,
		}

		tracer.track('filter_clear_clicked', params)
	},

	paginationClicked(page) {
		const { paginate } = $tracking.storage.get('searchTools')

		const params = {
			current_page: page,
			last_page: paginate.last_page,
			next_page: paginate.last_page > page ? page + 1 : null,
			per_page: paginate.per_page,
			total_pages: paginate.total,
		}

		tracer.track('pagination_clicked', params)
	},

	filterRoleClicked(newDeveloperType, oldDeveloperType) {
		if (newDeveloperType && oldDeveloperType) {
			const params = {
				old_developer_type: oldDeveloperType.name,
				new_developer_type: newDeveloperType.name,
			}

			tracer.track('filter_role_clicked', params)
		}
	},

	filterListingClicked(newQuery, oldQuery) {
		const newFilters = newQuery || {}
		const oldFilters = oldQuery || {}

		delete newFilters.page
		delete oldFilters.page

		const isUpdateFilter = JSON.stringify(newFilters) !== JSON.stringify(oldFilters)

		const params = {
			filters: newFilters,
			old_filters: oldFilters,
			updated_filters: isUpdateFilter,
		}

		tracer.track('filter_listing_clicked', params)
	},

	talentlyListingUpdated() {
		const { previous_url } = $tracking.storage.get('app')

		const { developer_type_name, paginate, old_filters, filters, start_time, end_time, candidates } =
			$tracking.storage.get('searchTools')

		if (!old_filters) return

		const newFilter = JSON.stringify(filters)
		const oldFilter = JSON.stringify(old_filters)
		if (newFilter === oldFilter) return

		const params = {
			affinity_average: averageAffinityOfCandidates(candidates),
			developer_type: developer_type_name,
			filters,
			loading_time: differenceInSecondsDates(start_time, end_time),
			total_results: paginate?.total,
			previous_url: previous_url || '',
		}

		tracer.track('talently_listing_updated', params)
		$tracking.storage.remove('searchToolAction')
	},
}
