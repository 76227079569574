import { useRequest } from '~/repositories'

export const getSupportResources = (params) => {
	return useRequest('get', `/v3/support/office/resources`, { params })
}

export const getCities = (params) => {
	return useRequest('get', `/v3/support/office/cities`, { params })
}

export default {
	getSupportResources,
	getCities,
}
