export const useScheduleDemo = () => {
	const router = useRouter()
	const localePath = useLocalePath()
	const { $tracking, $auth } = useNuxtApp()
	const config = useRuntimeConfig()
	const { locale } = useI18n()

	const urlCalendly = computed(() => {
		return config.public.calendly[locale.value]?.sales
	})

	const customAnswersDefault = computed(() => {
		if ($auth.user) {
			return {
				a1: $auth.user.business.name,
			}
		}
		return {}
	})

	const settingCalendly = computed(() => {
		return (utms?, customAnswers?) => {
			const name = $auth.user ? `${$auth.user.name} ${$auth.user.last_name}` : ''
			const email = $auth.user ? $auth.user.email : ''
			const utmTerm = $auth.user ? $auth.user.prospect_uuid : ''

			return {
				prefill: {
					name,
					email,
					customAnswers: {
						...customAnswersDefault.value,
						...customAnswers,
					},
				},
				utm: {
					utmTerm,
					...utms,
				},
			}
		}
	})

	const calendlyFullLoad = () => {
		$tracking.calendly.scheduleDemoLoaded()
	}

	const goToScheduled = () => {
		$tracking.calendly.scheduleDemoSuccessLoaded()
		router.push(localePath('/demo/scheduled'))
	}

	return { urlCalendly, settingCalendly, calendlyFullLoad, goToScheduled }
}
