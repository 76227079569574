import { useRequest } from '~/repositories'

export const getNotifications = (params) => {
	return useRequest('get', `/v4/hire/notifications`, { params })
}

export const postReadNotification = (id) => {
	return useRequest('post', `/v4/hire/notifications/${id}/read`)
}

export const postAnchorNotification = (id) => {
	return useRequest('post', `/v4/hire/notifications/${id}/anchor`)
}

export const patchNotificationMasAsReadWhenExternalChannel = (params) => {
	return useRequest('patch', '/v4/hire/notifications/external-channel/mark-as-read', params)
}

export default {
	getNotifications,
	postReadNotification,
	postAnchorNotification,
	patchNotificationMasAsReadWhenExternalChannel,
}
