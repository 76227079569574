import tracer from '~/tracking/provider'
import { useCalculator } from '~/composables/useSubscription'

const getInfoPlanEvent = (countOffers) => {
	const router = useRoute()
	const { $auth, $subscription } = useNuxtApp()
	const { getSelectedProductAndSubscription } = useCalculator()

	let origin = 'buy'

	if (router.path !== '/subscription/buy') {
		const newPrice = getSelectedProductAndSubscription.value?.subscription?.price
		const oldPrice = $subscription?.subscription?.details?.regular_price

		if (newPrice > oldPrice) {
			origin = 'upgrade'
		} else {
			origin = 'downgrade'
		}
	}

	return {
		origin,
		business_name: $auth.user?.business?.name,
		plan_type: getSelectedProductAndSubscription.value?.name,
		plan_data: {
			price: getSelectedProductAndSubscription.value?.subscription?.price,
			offers: getSelectedProductAndSubscription.value?.subscription?.nro_work_offers || countOffers,
		},
	}
}

export default {
	planPriceInteractionClick(countOffers) {
		const params = {
			...getInfoPlanEvent(countOffers),
			component: 'calculator',
		}

		tracer.track('plan_price_interaction_click', params)
	},

	planPriceInteractionClickTable(product, subscription) {
		const { $auth } = useNuxtApp()

		const params = {
			origin: 'buy',
			component: 'table',
			plan_type: product.name,
			business_name: $auth.user?.business?.name,
			plan_data: {
				price: subscription.price,
				offers: subscription.nro_work_offers,
			},
		}

		tracer.track('plan_price_interaction_click', params)
	},

	viewSubscriptionPage() {
		const { $auth } = useNuxtApp()

		const params = {
			business_name: $auth.user?.business?.name,
			plan_data: new Date().toISOString(),
		}

		tracer.track('suscription_view', params)
	},

	clickSelectedPlanButton(component = 'calculator') {
		const params = {
			...getInfoPlanEvent(),
			component,
		}

		tracer.track('click_selected_plan_button', params)
	},

	cancelEditPaymentCard() {
		tracer.track('cancel_edit_payment_card')
	},
}
