<template>
	<div class="h-screen flex items-center justify-center w-full text-center">
		<div class="mx-auto max-w-xl">
			<h1 class="text-primary-500 font-semibold text-xl mb-4 uppercase">
				<span v-if="props.error.statusCode === 404">{{ $t('Página no encontrada') }}</span>
				<span v-else>{{ $t('Ocurrió un error') }}</span>
			</h1>

			<div class="mb-6">
				<p v-if="props.error.message">{{ props.error.message }}</p>
			</div>

			<UButton
				to="/"
				size="lg"
				>{{ $t('Ir al inicio') }}</UButton
			>
		</div>
	</div>
</template>

<script setup>
const props = defineProps({
	error: {
		type: Object,
		required: true,
	},
})
</script>
