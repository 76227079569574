import { datadogLogs } from '@datadog/browser-logs'

export default defineNuxtPlugin(({ $config }) => {
	const { datadog } = $config.public

	const init = {
		clientToken: datadog.clientToken,
		site: datadog.site,
		service: datadog.service,
		env: datadog.env,
		forwardErrorsToLogs: true,
		sessionSampleRate: 100,
	}
	if (datadog.enabled) {
		datadogLogs.init(init)
	}
})
