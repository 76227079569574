import { useRequest } from '~/repositories'

export const getOffersSuggestions = (id) => {
	return useRequest('get', `/v4/hire/offers/${id}/suggestions`)
}

export const postOffersCandidateInterest = (id, params) => {
	return useRequest('post', `/v4/hire/offers/${id}/suggestions`, params)
}

export const postSkipSuggestions = (id) => {
	return useRequest('post', `/v4/hire/offers/${id}/suggestions/skip`)
}

export const getAskForHelp = () => {
	return useRequest('get', `/v4/hire/offers/ask-for-help`)
}

export const getAISuggestions = (id) => {
	return useRequest('get', `/v4/hire/offers/${id}/ai-suggestions`)
}

export const postCalculateMinimumSalary = (params) => {
	return useRequest('post', `/v4/hire/offers/calculate-minimum-salary`, params)
}
export const postGraphInterest = (params) => {
	return useRequest('post', `/v4/hire/offers/graph/interests`, params)
}

export const postImproveWithAI = (params) => {
	return useRequest('post', `/v4/hire/improve-with-ai`, params)
}

export const postTextToWorkOffer = (params) => {
	return useRequest('post', `/v4/hire/text-to-work-offer`, params)
}

export const getCandidateNotesInWorkOffer = (workOfferId, candidateId, params) => {
	return useRequest('get', `/v4/hire/offers/${workOfferId}/notes/${candidateId}`, params)
}

export const getAgendaModal = (workOfferId) => {
	return useRequest('get', `/v4/hire/offers/${workOfferId}/agenda-modal`)
}

export const trackAgendaModal = (workOfferId) => {
	return useRequest('post', `/v4/hire/offers/${workOfferId}/agenda-modal/track`)
}

export const postOfferNotification = (workOfferId, params) => {
	return useRequest('post', `/v4/hire/offers/${workOfferId}/notification/preferences`, params)
}

export default {
	getOffersSuggestions,
	postOffersCandidateInterest,
	postSkipSuggestions,
	getAskForHelp,
	getAISuggestions,
	postCalculateMinimumSalary,
	postGraphInterest,
	postImproveWithAI,
	postTextToWorkOffer,
	getCandidateNotesInWorkOffer,
	getAgendaModal,
	trackAgendaModal,
	postOfferNotification,
}
