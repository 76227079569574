import { useSupportStore } from '~/store/support'

export const useCalculator = () => {
	const { $subscription } = useNuxtApp()
	const { getResource } = storeToRefs(useSupportStore())

	const countOffers = useState<number>('countOffers', () => 1)
	const selectProductId = useState<any>('selectProductId', () => null)
	const selectedProduct = useState<any>('selectedProduct', () => null)

	const getSubscriptionByProduct = computed(() => {
		return (product) => {
			return getResource
				.value('services_upsells')
				.filter((item) => item.service_id === product.id)
				.find((item) => item.nro_work_offers === countOffers.value)
		}
	})

	const getSelectedProductAndSubscription = computed(() => {
		const findSubscription = getResource
			.value('services_upsells')
			.filter((item) => item.service_id === selectProductId.value)
			.find((item) => item.nro_work_offers === countOffers.value)

		const findProduct = $subscription.products.find((item) => item.id === selectProductId.value)

		return { ...findProduct, subscription: findSubscription }
	})

	const setSelectedProduct = (subscription) => {
		const findProduct = $subscription.products.find((item) => item.id === subscription.service_id)

		selectedProduct.value = { ...findProduct, subscription }
	}

	return {
		countOffers,
		selectProductId,
		selectedProduct,
		getSubscriptionByProduct,
		getSelectedProductAndSubscription,
		setSelectedProduct,
	}
}

export const usePlanDetails = () => {
	const { $subscription } = useNuxtApp()

	const getNamePlan = computed(() => {
		return $subscription.subscription?.name?.replace('Hire ', '')
	})

	const getRecurringPayment = computed(() => {
		return $subscription.subscription?.details?.regular_price || 0
	})

	const getRemainingOffers = computed(() => {
		// return $auth.user?.business?.remaining_offers || 0
		return $subscription.subscription?.details?.number_of_offers || 0
	})

	const getDateRenovation = computed(() => {
		return $subscription.subscription?.details?.period_end
	})

	return { getNamePlan, getRecurringPayment, getRemainingOffers, getDateRenovation }
}
