export const getNameElement = (elemento, attr = 'data-element') => {
	const dataName = elemento.getAttribute(attr)
	if (dataName !== null) return dataName

	let padre = elemento.parentElement

	while (padre) {
		const dataName = padre.getAttribute(attr)
		if (dataName !== null) {
			return dataName
		}
		padre = padre.parentElement
	}

	return null
}
