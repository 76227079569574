/*
Este middleware se encarga de cambiar el idioma de la aplicación según el idioma del usuario.
*/

export default defineNuxtRouteMiddleware((to) => {
	const { $auth, $i18n, $fetchHire, $axios, $date } = useNuxtApp()
	const currentLanguage = $i18n.locale.value

	// Cancelar si el usuario no está autenticado
	if (!$auth.loggedIn) {
		// Cambiar el idioma de las peticiones HTTP
		$axios.defaults.headers.common.lang = currentLanguage
		$fetchHire.defaults.headers.common.lang = currentLanguage
		$date.locale(currentLanguage)
		return
	}

	const userLanguage = $auth.user.language

	// Cambiar el idioma de las peticiones HTTP
	$axios.defaults.headers.common.lang = userLanguage
	$fetchHire.defaults.headers.common.lang = userLanguage
	$date.locale(userLanguage)

	// Si el idioma del usuario es el mismo que el idioma de la aplicación
	if (userLanguage === currentLanguage) {
		return
	}

	$i18n.locale.value = userLanguage
	const { defaultLocale } = $i18n

	// Agregar el prefijo del idioma si el idioma del usuario es igual al idioma por defecto
	if (defaultLocale === currentLanguage) {
		const newPath = `/${userLanguage}${to.fullPath}`

		if (newPath !== to.fullPath) {
			window.location.replace(newPath)
			return
		}

		return
	}

	// Quitar el prefijo de idioma si el idioma del usuario es igual al idioma por defecto
	if (defaultLocale === userLanguage) {
		const pathSegments = to.fullPath.split('/').slice(2)
		const newPath = '/' + pathSegments.join('/')

		if (newPath !== to.fullPath) {
			window.location.replace(newPath)
			return
		}

		return
	}

	// Cambiar el prefijo de idioma en otros casos
	const pathSegments = to.fullPath.split('/')
	pathSegments[1] = userLanguage
	const newPath = pathSegments.join('/')

	if (newPath !== to.fullPath) {
		window.location.replace(newPath)
	}
})
