import tracer from '~/tracking/provider'
import { useAuthStore } from '~/store/auth'
import { useOnboardingStore } from '~/store/onboarding'

const commonProperties = () => {
	const $auth = useAuthStore()
	const { getProgress } = useOnboardingStore()

	const listOffers = Object.values($auth.user?.offers)
	const countOffers = listOffers.reduce((acc, item) => acc + item, 0)

	return {
		current_plan: $auth.user?.business?.active_subscription?.label,
		ful_profile: getProgress === 100,
		offers_active: $auth.user?.offers?.active_count,
		offers_amount: countOffers,
	}
}

const getOriginUpsellingLimit = () => {
	const $auth = useAuthStore()
	const route = useRoute()

	const userLanguage = $auth.user.language

	if (route.name === `offers-idoffer-index___${userLanguage}`) {
		return 'candidatos de oferta'
	}

	if (route.name === `offers-idoffer-index-search___${userLanguage}`) {
		return 'busca de candidatos de oferta'
	}

	if (route.name === `candidates___${userLanguage}`) {
		return 'busca de candidatos'
	}

	return 'pipeline'
}

export default {
	upgradeSubscriptionHomeClick() {
		const params = {
			...commonProperties(),
		}

		tracer.track('upgrade_subscription_home_click', params)
	},

	upgradeSubscriptionOfferDisabledClick() {
		const params = {
			...commonProperties(),
		}

		tracer.track('upgrade_subscription_offer_disabled_click', params) // Home
	},

	upgradeSubscriptionWizardClick() {
		const params = {
			...commonProperties(),
		}

		tracer.track('upgrade_subscription_wizard_click', params)
	},

	upgradeSubscriptionRejectedClick() {
		const params = {
			...commonProperties(),
			origin: getOriginUpsellingLimit(),
		}

		tracer.track('upgrade_subscription_rejected_click', params)
	},

	upgradeSubscriptionCandidatesClick() {
		const params = {
			...commonProperties(),
			origin: getOriginUpsellingLimit(),
		}

		tracer.track('upgrade_subscription_candidates_click', params)
	},

	scheduleProOpened(origin, type) {
		const params = {
			origin,
			type,
		}

		tracer.track('schedule_pro_opened', params)
	},

	scheduleProHome() {
		tracer.track('schedule_pro_home')
	},

	scheduleProOffer() {
		tracer.track('schedule_pro_offer')
	},

	scheduleProSearch() {
		tracer.track('schedule_pro_search')
	},

	scheduleProHomePhone() {
		tracer.track('schedule_pro_home_phone')
	},

	scheduleProOffersSpects() {
		tracer.track('schedule_pro_offer_spects')
	},
}
