import tracer from '~/tracking/provider'

export default {
	scheduleDemoLoaded() {
		const params = {}
		tracer.track('schedule_demo_loaded', params)
	},
	scheduleDemoSuccessLoaded() {
		const params = {}
		tracer.track('schedule_demo_success_loaded', params)
	},
}
