const initialState = {}

export const useOnboardingStore = defineStore('onboarding', {
	state: () => ({ ...initialState }),
	getters: {
		getOnboarding() {
			const { $auth, $i18n } = useNuxtApp()
			const $t = $i18n.t

			return [
				{
					label: $t('Regístrate en Talently.'),
					to: '/',
					type: 'link',
					completed: true,
					label_task: $t('completar'),
				},
				{
					label: $t('Crea tu primera búsqueda y envíala para revisión.'),
					to: '/offer/import',
					type: 'link',
					completed: $auth.user.business.has_sent_first_offer,
					label_task: $t('cargar'),
				},
				{
					label: $t('Completa el perfil de tu empresa.'),
					to: '/account/company',
					type: 'link',
					completed: $auth.user.business.has_completed_profile,
					label_task: $t('completar'),
				},
				{
					label: $t('Valida tu correo electrónico.'),
					to: '',
					type: 'modal',
					completed: $auth.user.has_verified_email,
					label_task: $t('validar'),
				},
			]
		},
		getProgress() {
			return (this.getOnboarding.filter((item) => item.completed).length / this.getOnboarding.length) * 100
		},
	},
})
