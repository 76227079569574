import { SQ2_AUTOMATION_15_PAUSE_5_DISABLE } from '~/config/featureFlags'

const initialState = {
	offer: null,
	dashboard_count: {
		discard: 0,
		hired_candidates: 0,
		ia_candidates: 0,
		in_process_candidates: 0,
		inbox: 0,
		refered_candidates: 0,
		rejected_candidates: 0,
	},
}

export const useOfferStore = defineStore('offer', {
	state: () => ({ ...initialState }),
	actions: {
		updateDashboardCount(key, type) {
			if (!this.dashboard_count[key]) return

			if (type === 'add') {
				this.dashboard_count[key] += 1
			}

			if (type === 'subtract' && this.dashboard_count[key] > 0) {
				this.dashboard_count[key] -= 1
			}
		},
		async createOffer(params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.postOffer(params)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async updateOfferById(offerId, params) {
			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.postUpdateOffer(offerId, params)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async fetchDetailsOffer(offerId) {
			if (this.offer && this.offer.id === offerId) {
				return { success: true, result: this.offer }
			}

			const { $repository } = useNuxtApp()
			const response = await $repository.v3.company.offers.getOffer(offerId)

			if (response.success) {
				this.offer = response.result
			}

			return response
		},
		async fetchDashboardDetails(idOffer: string | number) {
			const { $repository, $hasFeature } = useNuxtApp()
			const response = $hasFeature(SQ2_AUTOMATION_15_PAUSE_5_DISABLE)
				? await $repository.v4.company.offers.getDashboardDetails(idOffer)
				: await $repository.v3.company.offers.getDashboardDetails(idOffer)

			if (response.success) {
				const { work_offer, dashboard_count } = response.result
				this.offer = work_offer
				this.dashboard_count = dashboard_count
			}

			return response
		},
	},
})
