export const listErrors = (errors) => {
	const listKeys = Object.keys(errors).filter((key) => {
		return (errors[key]?.error && errors[key]?.error.length > 0) || errors[key].length > 0
	})

	const errorFields = listKeys
	let rawErrors = null

	if (listKeys.length) {
		rawErrors = {}

		listKeys.forEach((key) => {
			if (Array.isArray(errors[key])) {
				rawErrors[key] = [...errors[key]]
				return
			}

			if (typeof errors[key] === 'string') {
				rawErrors[key] = [errors[key]]
				return
			}

			if (typeof errors[key] === 'object' && errors[key].error) {
				rawErrors[key] = [errors[key].error]
			}
		})
	}

	return {
		count: errorFields.length,
		error_fields: errorFields,
		raw_errors: rawErrors,
	}
}
