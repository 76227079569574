import tracer from '~/tracking/provider'

export default {
	validationMailClick() {
		const params = {}

		tracer.track('usuario-activado', params)
		tracer.track('validation_mail_click', params)
	},
}
