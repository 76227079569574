import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage.js'

const addViewCandidateCounter = () => {
	const surveyMetrics = $tracking.storage.get('surveyMetrics')

	$tracking.storage.set('surveyMetrics', {
		...surveyMetrics,
		...{
			counter_view_candidate: (surveyMetrics.counter_view_candidate || 0) + 1,
		},
	})
}

const resetViewCandidateCounter = () => {
	const surveyMetrics = $tracking.storage.get('surveyMetrics')

	$tracking.storage.set('surveyMetrics', {
		...surveyMetrics,
		...{
			counter_view_candidate: 0,
		},
	})
}

export default {
	// https://hotjar.com/l/qEkZ90
	npsFullProfile() {
		tracer.track('nps_full_profile')
	},

	filterSearchTool() {
		const { $subscription } = useNuxtApp()

		if ($subscription.isSubscriptionFree) {
			const surveyMetrics = $tracking.storage.get('surveyMetrics')

			$tracking.storage.set('surveyMetrics', {
				...surveyMetrics,
				...{
					counter_search_hire: (surveyMetrics.counter_search_hire || 0) + 1,
				},
			})
		}
	},

	// https://hotjar.com/l/JrRE75
	csatFreeOffer() {
		const surveyMetrics = $tracking.storage.get('surveyMetrics')

		addViewCandidateCounter()

		if (surveyMetrics.counter_view_candidate >= 3 && surveyMetrics.counter_search_hire >= 2) {
			tracer.track('csat_free_offer')
			resetViewCandidateCounter()
		}
	},

	// https://hotjar.com/l/nL9Vrx
	csatLiteOffer(candidate) {
		const surveyMetrics = $tracking.storage.get('surveyMetrics')

		if (candidate.sended_by === 'ia') {
			addViewCandidateCounter()

			if (surveyMetrics.counter_view_candidate >= 3) {
				tracer.track('csat_lite_offer')
				resetViewCandidateCounter()
			}
		}
	},

	// https://hotjar.com/l/pQdV7D
	csatProOffer(candidate) {
		const surveyMetrics = $tracking.storage.get('surveyMetrics')

		if (candidate.sended_by === 'office') {
			addViewCandidateCounter()

			if (surveyMetrics.counter_view_candidate >= 1) {
				tracer.track('csat_pro_offer')
				resetViewCandidateCounter()
			}
		}
	},

	// https://hotjar.com/l/JBOxP4
	cesOfferSubmit() {
		tracer.track('ces_offer_submit')
	},
}
