import tracer from '~/tracking/provider'
import { useAuthStore } from '~/store/auth'

export default {
	identify(userParams = {}) {
		const $auth = useAuthStore()
		const user = $auth.user

		if (user === null) return

		const params = {
			...userParams,
			id: user.id,
			avatar: user.avatar,
			email: user.email,
			firstName: user.name,
			lastName: user.last_name || '',
			name: `${user.name} ${user.last_name || ''}`,
			business_id: user.business.id,
			business_user_id: user.id,
			rol: user.roles[0],
			is_main_account: user.is_main_account,
			business_prospect_source_id: user.business.source_id,
			company: {
				id: user.business.id,
				name: user.business.name,
				industry: user.business?.sector?.label,
			},
		}

		if (user.is_main_account) {
			if (!user.name) {
				params.name = user.business.name
				params.firstName = user.business.name
			}

			if (!user.avatar) {
				params.avatar = user.business.logo_path
			}
		}

		tracer.identify(user.email, params)
	},

	logInClicked() {
		const params = {}

		tracer.track('login_clicked', params)
	},

	alias(oldId, newId) {
		tracer.alias(oldId, newId)
	},

	logOut() {
		const params = {}

		tracer.track('logout', params)
		tracer.reset()
	},
}
