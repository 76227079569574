const prefix = 'talently.tracer'

const addLocalCounter = (newValues, oldValues) => {
	const listNewCounters = Object.keys(newValues).filter((item) => item.includes('_counter'))

	const listOldCounters = Object.keys(oldValues).filter((item) => item.includes('_counter'))

	if (listNewCounters.length && listOldCounters.length) {
		listNewCounters.forEach((item) => {
			const newCounter = newValues[item]
			const oldCounter = oldValues[item]
			newValues[item] = newCounter + oldCounter
		})

		return newValues
	}

	return newValues
}

const setTrackingTemporarily = (key, value) => {
	if (value === undefined) return

	if (typeof value !== 'object') return
	if (Array.isArray(value)) return

	const getValue = localStorage.getItem(`${prefix}.${key}`)

	if (!getValue) {
		localStorage.setItem(`${prefix}.${key}`, JSON.stringify(value))
		return
	}

	const getValueParse = JSON.parse(getValue)
	const newValue = addLocalCounter(value, getValueParse)
	const newValueInString = JSON.stringify({ ...getValueParse, ...newValue })

	localStorage.setItem(`${prefix}.${key}`, newValueInString)
}

const getTrackingTemporarily = (key) => {
	const getVale = localStorage.getItem(`${prefix}.${key}`)
	if (!getVale) return {}
	return JSON.parse(getVale)
}

const removeTrackingTemporarily = (key) => {
	localStorage.removeItem(`${prefix}.${key}`)
}

export default {
	storage: {
		set: setTrackingTemporarily,
		get: getTrackingTemporarily,
		remove: removeTrackingTemporarily,
	},
}
