import { RudderAnalytics } from '@rudderstack/analytics-js'
export const analytics = new RudderAnalytics()

window.rudderanalytics = analytics

type ConfigProvide = {
	key: string
	url: string
}

type Properties = {
	[key: string]: string | number | boolean
}

const tracer = {
	load(config: ConfigProvide) {
		analytics.load(config.key, config.url)
	},
	identify(userId: string, params?: Properties) {
		analytics.identify(userId, {
			...params,
			origin: 'hire',
		})
	},
	page() {
		analytics.page()
	},
	track(name: string, params?: Properties) {
		analytics.track(name, {
			...params,
			origin: 'hire',
		})
	},
	alias(previousId: string, userId: string) {
		analytics.alias(previousId, userId)
	},
	reset() {
		analytics.reset(true)
	},
	getUserId() {
		return analytics.getUserId()
	},
	getAnonymousId() {
		return analytics.getAnonymousId()
	},
}

export default tracer
