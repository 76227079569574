import axios from 'axios'
import { useAuthStore } from '~/store/auth'

const templateError = {
	success: false,
	status: '',
	message: 'Error desconocido',
}

export default defineNuxtPlugin(({ provide, $config }) => {
	const auth = useAuthStore()
	const { $i18n } = useNuxtApp()

	const $fetchHire = axios.create({
		baseURL: $config.public.apiBase,
		headers: {
			lang: $i18n.locale.value,
		},
	})

	// Add a request interceptor
	$fetchHire.interceptors.request.use(
		function (config) {
			return config
		},
		function (error) {
			return Promise.reject(error)
		},
	)

	// Add a response interceptor
	$fetchHire.interceptors.response.use(
		function (response) {
			return response
		},
		function (error) {
			if (!error.response) return Promise.reject(templateError)
			const responseData = error.response.data

			// Preparación de respuesta de error
			const errorResponse = {
				data: { ...responseData, statusCode: error.response.status },
			}

			return Promise.reject(errorResponse)
		},
	)

	provide('fetchHire', $fetchHire)
	provide('auth', auth)
})
