/*
En este Middleware se verifica el flujo del usuario, completar registros, wizard, etc.
*/

import { useAuthStore } from '~/store/auth'
import steps from '~/assets/support/wizard'

export default defineNuxtRouteMiddleware((to) => {
	const { path, query } = to
	const { $localePath } = useNuxtApp()
	const { user, loggedIn } = useAuthStore()

	if (!loggedIn) return
	if (path === $localePath('/logout')) return

	// Elimina los querys de token y gate
	const newQuery = { ...query }
	delete newQuery.token
	delete newQuery.gate

	const isWizardCompleted = user?.wizard.completed
	const isPathWizard = path.includes('/register')

	// Si el usuario ya completó el wizard
	if (isWizardCompleted) {
		if (query.to && typeof query.to === 'string') {
			delete newQuery.to

			return navigateTo($localePath({ path: query.to, query: newQuery }))
		}

		// Si intenta entrar al wizard lo redireccionamos al home
		if (isPathWizard) {
			return navigateTo($localePath({ path: '/', query: newQuery }))
		}

		// Si aun la query tiene token o gate eliminamos
		if (query.token || query.gate) {
			return navigateTo($localePath({ path, query: newQuery }))
		}

		return
	}

	// Si el usuario no ha completado el wizard

	// Busca el paso actual
	const currentStep = steps.find((item) => item.wizard_step_id === user?.wizard.id)

	// Redirecciona al paso correcto
	if (currentStep && path !== $localePath(currentStep.path)) {
		return navigateTo($localePath(currentStep.path))
	}
})
