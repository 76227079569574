import { useRequest } from '~/repositories'

export const getScheduledInterviews = () => {
	return useRequest('get', `/v3/company/interviews/scheduled`)
}

export const postCancelInterview = (params) => {
	return useRequest('post', `/v3/company/interviews/cancel`, params)
}

export default {
	getScheduledInterviews,
	postCancelInterview,
}
