import { useRequest } from '~/repositories'

export const getSearchCandidates = (params) => {
	return useRequest('get', `/v4/hire/candidates`, { params })
}

export const getCandidateById = (id, params) => {
	return useRequest('get', `/v4/hire/candidate/${id}`, { params })
}

export const postViewCandidateProfileBySearch = (id, params) => {
	return useRequest('post', `/v4/hire/candidate/${id}/view-profile-by-search`, params)
}

export default {
	getSearchCandidates,
	getCandidateById,
	postViewCandidateProfileBySearch,
}
