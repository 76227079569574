// import tracer from '~/tracking/provider'

export default {
	closeBoxNotifications() {
		// const { startedAt, noInteraction } = $tracking.storage.get('notifications')
		// const sidebarOpeTime = new Date().getTime() - startedAt.getTime()
		//
		// const eventData = {
		//   'iniciado el': startedAt,
		//   'seccion visitada': window.location.href,
		//   'notificaciones sin leer': countNotifications,
		//   'tiempo abierto': sidebarOpeTime,
		//   'no interaccion': noInteraction ?? true,
		// }
		//
		// tracer.track({ event: 'notification_sidebar', eventData })
	},
	backFromNotificationsEvent() {
		// if (!NOTIFICATION_ID[notification?.communicable_id]) return
		//
		// const eventData = {
		//   'ID de notificacion': NOTIFICATION_ID[notification.communicable_id],
		//   'Canal de apertura': 'platform',
		// }
		//
		// tracer.track({ event: 'regreso_desde_notification', eventData })
	},
}
