export default [
	{
		wizard_step_id: 1,
		path: '/register/password-setup',
	},
	{
		wizard_step_id: 2,
		path: '/',
	},
	{
		wizard_step_id: 3,
		path: '/register/profile-setup',
	},
]
