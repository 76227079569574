import { useRequest } from '~/repositories'

export const postLogin = (params) => {
	return useRequest('post', `/v3/hire/auth/login`, params)
}

export const getLogout = (params) => {
	return useRequest('post', `/v3/hire/auth/logout`, params)
}

export const postPasswordSetup = (params) => {
	return useRequest('post', `/v3/hire/password-setup`, params)
}

export const postProfileSetup = (params) => {
	return useRequest('post', `/v3/hire/profile-setup`, params)
}

export const postForgotPassword = (params) => {
	return useRequest('post', `/v3/hire/forgot-password`, params)
}

export const postResetPassword = (params) => {
	return useRequest('post', `/v3/hire/reset-password`, params)
}

export const postValidateToken = (params) => {
	return useRequest('post', `/v3/hire/auth/validate-token`, params)
}

export const postValidateEmail = (params) => {
	return useRequest('post', `/v3/hire/auth/validate-token-by-email`, params)
}

export const getMe = () => {
	return useRequest('get', `/v3/hire/auth/me`)
}

export const postNotifyUser = (userId) => {
	return useRequest('post', `/v3/hire/send-activation-email/${userId}`)
}

export const postUpdateProfile = (params) => {
	return useRequest('post', `/v3/hire/update-profile`, params)
}

export const deleteBusinessLogoProfile = (businessId) => {
	return useRequest('delete', `/v3/hire/delete-logo/${businessId}`)
}

export const postUpdateBillingInfo = (params) => {
	return useRequest('post', `/v3/hire/update-billing-data`, params)
}

export const getBillingData = () => {
	return useRequest('get', `/v3/hire/billing-data`)
}

export const postSurveyShowed = (surveyId) => {
	return useRequest('post', `/v3/hire/survey-showed/${surveyId}`)
}

export default {
	postLogin,
	getLogout,
	getMe,
	postPasswordSetup,
	postProfileSetup,
	postForgotPassword,
	postResetPassword,
	postValidateToken,
	postValidateEmail,
	postNotifyUser,
	postUpdateProfile,
	deleteBusinessLogoProfile,
	postUpdateBillingInfo,
	getBillingData,
	postSurveyShowed,
}
