import { useRequest } from '~/repositories'

export const postBillingInformation = (businessId, params) => {
	return useRequest('post', `/v4/hire/businesses/${businessId}/billing-information`, params)
}

export const postBuySubscription = (businessId, params) => {
	return useRequest('post', `/v4/hire/businesses/${businessId}/buy-subscription`, params)
}

export const getCurrentPlan = (businessId) => {
	return useRequest('get', `/v4/hire/businesses/${businessId}/current-plan`)
}

export default {
	postBillingInformation,
	postBuySubscription,
	getCurrentPlan,
}
