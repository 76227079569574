import tracer from '~/tracking/provider'

const getInfoCurrentPlanEvent = () => {
	const router = useRoute()
	const { $subscription, $auth } = useNuxtApp()

	let viewed_in = 'Vista de pago exitoso'

	if (router.path === '/account/billing') {
		viewed_in = 'Vista de facturación'
	}

	return {
		viewed_in,
		business_name: $auth.user?.business?.name,
		plan_type: $subscription?.name,
		plan_data: {
			price: $subscription?.subscription?.price,
			offers: $subscription?.subscription?.nro_work_offers,
		},
	}
}

export default {
	dataInvoiceSave(form) {
		const params = {
			...getInfoCurrentPlanEvent(),
			social_reason: form.company_name,
			id_number: form.tax_identification_number,
			business_address: form.fiscal_address_company,
		}

		tracer.track('data_invoice_save', params)
	},
}
