import { useRequest } from '~/repositories'

export const postAcceptOrRejectCandidate = (idOffer, idCandidate, payload) => {
	return useRequest('post', `/v3/company/offers/${idOffer}/${idCandidate}`, payload)
}

export const getTimeAvailability = (id) => {
	return useRequest('get', `/v3/company/candidate/${id}/time-availability`)
}

export const postScheduleInterview = (id, data) => {
	return useRequest('post', `/v3/company/candidate/${id}/schedule`, data)
}

export const postFeedbackSuggestions = (data) => {
	return useRequest('post', `/v3/company/candidate/recommended-feedback-to-ia`, data)
}

export default {
	postAcceptOrRejectCandidate,
	getTimeAvailability,
	postScheduleInterview,
	postFeedbackSuggestions,
}
