import { GrowthBook } from '@growthbook/growthbook'
import tracer from '~/tracking/provider'

export const gbAttributes = (user: any) => {
	return {
		id: user.id,
		email: user.email,
	}
}

export default defineNuxtPlugin(({ provide }) => {
	const config = useRuntimeConfig()
	const { feature_flags } = config.public

	const growthBook = new GrowthBook({
		apiHost: feature_flags.apiHost,
		clientKey: feature_flags.clientKey,
		enableDevMode: feature_flags.enableDevMode,
		trackingCallback: (experiment, result) => {
			console.log('Experiment Viewed', {
				experimentId: experiment.key,
				variationId: result.key,
			})
			tracer.track('Experiment Viewed', {
				experimentId: experiment.key,
				variationId: result.key,
			})
		},
	})

	provide('feature', growthBook)

	// Feature Boolean
	provide('hasFeature', (feature: string) => growthBook.isOn(feature.toLowerCase()))
})
