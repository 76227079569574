/*
Este middleware destinado a la autenticación de la aplicación, toda la seguridad de rutas públicas, privadas y guest.
*/

import { datadogLogs } from '@datadog/browser-logs'

export default defineNuxtRouteMiddleware(async (to) => {
	const { $localePath, $auth } = useNuxtApp()
	const { path, meta, fullPath } = to

	if (path === $localePath('/logout')) return

	const cookieToken = useCookie('auth.business.token')
	const cookieRedirect = useCookie('auth.hire.redirect')

	// Todos los que no esten logueados y quieran entrar a signup
	if (path === $localePath('/register') && !cookieToken.value) {
		return
	}

	// Si no hay token
	if (!cookieToken.value) {
		if (meta.auth !== 'guest') {
			cookieRedirect.value = fullPath
		}

		if (meta.auth === 'guest') return
		return navigateTo($localePath(`/login`))
	}

	// Guardamos el token en el store
	$auth.ADD_TOKEN(cookieToken.value)

	if ($auth.user === null) {
		const { success } = await $auth.fetchMe()

		// Si falla mandamos al login
		if (!success) {
			$auth.REMOVE_TOKEN()
			if (datadogLogs.getUser()) {
				datadogLogs.clearUser()
			}
			return navigateTo($localePath(`/login`))
		}
	}

	// Si la ruta es /login/ o /register/ mandamos al home
	if (['/login', '/register'].includes(path)) {
		return navigateTo($localePath(`/`))
	}

	// Si son rutas guest redirigimos al home
	if (meta.auth === 'guest') {
		return navigateTo($localePath(`/`))
	}
})
