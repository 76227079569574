import { useRequest } from '~/repositories'

export const getUsersList = () => {
	return useRequest('get', `/v4/hire/business-users/`)
}

export const postUser = (params) => {
	return useRequest('post', `/v4/hire/business-users/`, params)
}

export const deleteUser = (params) => {
	return useRequest('delete', `/v4/hire/business-users/${params}`)
}

export const getUser = (params) => {
	return useRequest('get', `/v4/hire/business-users/${params}`)
}

export const postEditUser = (userId, params) => {
	return useRequest('post', `/v4/hire/business-users/${userId}`, params)
}

export const getRequestCancelSubscription = () => {
	return useRequest('get', `/v4/hire/subscriptions/unsubscription-request/`)
}

export default {
	getUsersList,
	postUser,
	deleteUser,
	getUser,
	postEditUser,
	getRequestCancelSubscription,
}
