import { useRequest } from '~/repositories'

export const postLogin = (params) => {
	return useRequest('post', `/v3/company/auth/login`, params)
}

export const getMe = () => {
	return useRequest('get', `/v3/company/auth/me`)
}

export default {
	postLogin,
	getMe,
}
