import { default as billingd97nUknrwvMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/billing.vue?macro=true";
import { default as companyl7UOMaIbBzMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/company.vue?macro=true";
import { default as plan7Nlje6ktOpMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/plan.vue?macro=true";
import { default as indexjRcy2XcE11Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/account/index.vue?macro=true";
import { default as callbackCIm0temUZjMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/auth/callback.vue?macro=true";
import { default as _91token_93T0xhoPjMq5Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/[token].vue?macro=true";
import { default as indexG4ZMlUzZdUMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/index.vue?macro=true";
import { default as indexee3o8fKv9yMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/[idcandidate]/index.vue?macro=true";
import { default as best_45by_45roleCqfsEIn9zeMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/best-by-role.vue?macro=true";
import { default as indexQPr09GkTsjMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/index.vue?macro=true";
import { default as scheduledh5kyLwAwKBMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/demo/scheduled.vue?macro=true";
import { default as indexo2KZY8Ya3wMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/index.vue?macro=true";
import { default as loginFBk3hT1aSSMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/login.vue?macro=true";
import { default as logoutz4SddPA9AMMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/logout.vue?macro=true";
import { default as indexooM5jeTm34Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create/index.vue?macro=true";
import { default as createbC0kuVuEhjMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create.vue?macro=true";
import { default as importZG2NMcQlA4Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offer/import.vue?macro=true";
import { default as detailsKhlnCPoNVgMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue?macro=true";
import { default as indexxS3aWTlD4vMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue?macro=true";
import { default as technical_45requirementsS8WpwoC5QWMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue?macro=true";
import { default as editJm1fMyW3D9Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit.vue?macro=true";
import { default as details6jYTzV2whzMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/details.vue?macro=true";
import { default as discarded0fG6Kam8ByMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue?macro=true";
import { default as indexCIUisOA1CpMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/index.vue?macro=true";
import { default as pipelineuBklYT2pQ9Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue?macro=true";
import { default as searchiQEfw4EpFBMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/search.vue?macro=true";
import { default as indexEolpn3C0b2Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index.vue?macro=true";
import { default as password_45resetTYkxwHyyCRMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/password-reset.vue?macro=true";
import { default as indexRnfQ01hUi8Meta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/register/index.vue?macro=true";
import { default as password_45setupgzA4GNANhUMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/register/password-setup.vue?macro=true";
import { default as profile_45setupw8cOvL2CBRMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/register/profile-setup.vue?macro=true";
import { default as buykCwA1ujxuZMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/buy.vue?macro=true";
import { default as payment_45methodrPtaGVHXAlMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/payment-method.vue?macro=true";
import { default as successfulySnaXm8YXGMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/successful.vue?macro=true";
import { default as updateLtoeAf25OuMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/update.vue?macro=true";
import { default as indexjMFhw7HfaVMeta } from "/codebuild/output/src4167853712/src/frontend-hire/pages/users/index.vue?macro=true";
import { default as component_45stub1QzeDps0mVMeta } from "/codebuild/output/src4167853712/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stub1QzeDps0mV } from "/codebuild/output/src4167853712/src/frontend-hire/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.7_eslint@9.13.0_jiti@2.3.3__ioredis@5.4.1__ofnxr56xffz7kcza5pus2qn4u4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account___es",
    path: "/account",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___es",
    path: "billing",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___es",
    path: "company",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___es",
    path: "plan",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "account___en",
    path: "/en/account",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index.vue"),
    children: [
  {
    name: "account-index-billing___en",
    path: "billing",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/billing.vue")
  },
  {
    name: "account-index-company___en",
    path: "company",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/company.vue")
  },
  {
    name: "account-index-plan___en",
    path: "plan",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/account/index/plan.vue")
  }
]
  },
  {
    name: "auth-callback___es",
    path: "/auth/callback",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-callback___en",
    path: "/en/auth/callback",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/callback.vue")
  },
  {
    name: "auth-reset-password-token___es",
    path: "/auth/reset-password/:token()",
    meta: _91token_93T0xhoPjMq5Meta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password-token___en",
    path: "/en/auth/reset-password/:token()",
    meta: _91token_93T0xhoPjMq5Meta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/[token].vue")
  },
  {
    name: "auth-reset-password___es",
    path: "/auth/reset-password",
    meta: indexG4ZMlUzZdUMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: indexG4ZMlUzZdUMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/auth/reset-password/index.vue")
  },
  {
    name: "candidates-idcandidate___es",
    path: "/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-idcandidate___en",
    path: "/en/candidates/:idcandidate()",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/[idcandidate]/index.vue")
  },
  {
    name: "candidates-best-by-role___es",
    path: "/candidates/best-by-role",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates-best-by-role___en",
    path: "/en/candidates/best-by-role",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/best-by-role.vue")
  },
  {
    name: "candidates___es",
    path: "/candidates",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "candidates___en",
    path: "/en/candidates",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/candidates/index.vue")
  },
  {
    name: "demo-scheduled___es",
    path: "/demo/scheduled",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "demo-scheduled___en",
    path: "/en/demo/scheduled",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/demo/scheduled.vue")
  },
  {
    name: "index___es",
    path: "/",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/index.vue")
  },
  {
    name: "login___es",
    path: "/login",
    meta: loginFBk3hT1aSSMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginFBk3hT1aSSMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/login.vue")
  },
  {
    name: "logout___es",
    path: "/logout",
    meta: logoutz4SddPA9AMMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/logout.vue")
  },
  {
    name: "logout___en",
    path: "/en/logout",
    meta: logoutz4SddPA9AMMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/logout.vue")
  },
  {
    name: createbC0kuVuEhjMeta?.name,
    path: "/offer/create",
    meta: createbC0kuVuEhjMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___es",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: createbC0kuVuEhjMeta?.name,
    path: "/en/offer/create",
    meta: createbC0kuVuEhjMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create.vue"),
    children: [
  {
    name: "offer-create___en",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/create/index.vue")
  }
]
  },
  {
    name: "offer-import___es",
    path: "/offer/import",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: "offer-import___en",
    path: "/en/offer/import",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offer/import.vue")
  },
  {
    name: editJm1fMyW3D9Meta?.name,
    path: "/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___es",
    path: "details",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___es",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___es",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: editJm1fMyW3D9Meta?.name,
    path: "/en/offers/:idoffer()/edit",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit.vue"),
    children: [
  {
    name: "offers-idoffer-edit-details___en",
    path: "details",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/details.vue")
  },
  {
    name: "offers-idoffer-edit___en",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/index.vue")
  },
  {
    name: "offers-idoffer-edit-technical-requirements___en",
    path: "technical-requirements",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/edit/technical-requirements.vue")
  }
]
  },
  {
    name: indexEolpn3C0b2Meta?.name,
    path: "/offers/:idoffer()",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___es",
    path: "details",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___es",
    path: "discarded",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___es",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___es",
    path: "pipeline",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___es",
    path: "search",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: indexEolpn3C0b2Meta?.name,
    path: "/en/offers/:idoffer()",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index.vue"),
    children: [
  {
    name: "offers-idoffer-index-details___en",
    path: "details",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/details.vue")
  },
  {
    name: "offers-idoffer-index-discarded___en",
    path: "discarded",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/discarded.vue")
  },
  {
    name: "offers-idoffer-index___en",
    path: "",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/index.vue")
  },
  {
    name: "offers-idoffer-index-pipeline___en",
    path: "pipeline",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/pipeline.vue")
  },
  {
    name: "offers-idoffer-index-search___en",
    path: "search",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/offers/[idoffer]/index/search.vue")
  }
]
  },
  {
    name: "password-reset___es",
    path: "/password-reset",
    meta: password_45resetTYkxwHyyCRMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "password-reset___en",
    path: "/en/password-reset",
    meta: password_45resetTYkxwHyyCRMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/password-reset.vue")
  },
  {
    name: "register___es",
    path: "/register",
    meta: indexRnfQ01hUi8Meta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: indexRnfQ01hUi8Meta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/index.vue")
  },
  {
    name: "register-password-setup___es",
    path: "/register/password-setup",
    meta: password_45setupgzA4GNANhUMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-password-setup___en",
    path: "/en/register/password-setup",
    meta: password_45setupgzA4GNANhUMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/password-setup.vue")
  },
  {
    name: "register-profile-setup___es",
    path: "/register/profile-setup",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "register-profile-setup___en",
    path: "/en/register/profile-setup",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/register/profile-setup.vue")
  },
  {
    name: "subscription-buy___es",
    path: "/subscription/buy",
    meta: buykCwA1ujxuZMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-buy___en",
    path: "/en/subscription/buy",
    meta: buykCwA1ujxuZMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/buy.vue")
  },
  {
    name: "subscription-payment-method___es",
    path: "/subscription/payment-method",
    meta: payment_45methodrPtaGVHXAlMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-payment-method___en",
    path: "/en/subscription/payment-method",
    meta: payment_45methodrPtaGVHXAlMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/payment-method.vue")
  },
  {
    name: "subscription-successful___es",
    path: "/subscription/successful",
    meta: successfulySnaXm8YXGMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-successful___en",
    path: "/en/subscription/successful",
    meta: successfulySnaXm8YXGMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/successful.vue")
  },
  {
    name: "subscription-update___es",
    path: "/subscription/update",
    meta: updateLtoeAf25OuMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "subscription-update___en",
    path: "/en/subscription/update",
    meta: updateLtoeAf25OuMeta || {},
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/subscription/update.vue")
  },
  {
    name: "users___es",
    path: "/users",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: "users___en",
    path: "/en/users",
    component: () => import("/codebuild/output/src4167853712/src/frontend-hire/pages/users/index.vue")
  },
  {
    name: component_45stub1QzeDps0mVMeta?.name,
    path: "/profile",
    component: component_45stub1QzeDps0mV
  },
  {
    name: component_45stub1QzeDps0mVMeta?.name,
    path: "/comprar-suscripcion",
    component: component_45stub1QzeDps0mV
  },
  {
    name: component_45stub1QzeDps0mVMeta?.name,
    path: "/offers",
    component: component_45stub1QzeDps0mV
  }
]