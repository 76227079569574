import { useRequest } from '~/repositories'

export const setLanguage = (params) => {
	return useRequest('post', '/v3/hire/set-language', params)
}

export const getCheckId = (params) => {
	return useRequest('get', '/v3/hire/validate-uuid', { params })
}

export const postSendActivationMail = (params) => {
	return useRequest('get', '/v3/hire/send-activation-mail', { params })
}

export const postScheduleCall = (params) => {
	return useRequest('post', `/v3/hire/schedule-call/`, params)
}

export const postRegisterCompany = (payload) => {
	return useRequest('post', '/v3/hire', payload)
}

export default {
	setLanguage,
	getCheckId,
	postSendActivationMail,
	postScheduleCall,
	postRegisterCompany,
}
