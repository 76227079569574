import tracer from '~/tracking/provider'
import storage from '~/tracking/utils/storage'
import identity from '~/tracking/utils/identity'
import events from '~/tracking/events'

export default defineNuxtPlugin(({ provide, $config }) => {
	const { data_integration_platform } = $config.public

	if (data_integration_platform.enabled) {
		tracer.load({
			url: data_integration_platform.cdn,
			key: data_integration_platform.key,
		})
	}

	provide('tracking', {
		...storage,
		...events,
		...identity,
	})
})
