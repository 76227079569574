import axios from 'axios'

const templateError = {
	success: false,
	status: '',
	message: 'Error desconocido',
}

export default defineNuxtPlugin(({ provide, $config }) => {
	const $axios = axios.create({
		baseURL: $config.public.apiBase,
	})

	// Add a request interceptor
	$axios.interceptors.request.use(
		function (config) {
			return config
		},
		function (error) {
			return Promise.reject(error)
		},
	)

	// Add a response interceptor
	$axios.interceptors.response.use(
		function (response) {
			return response
		},
		function (error) {
			if (!error.response) return Promise.reject(templateError)
			const responseData = error.response.data

			// Preparación de respuesta de error
			const errorResponse = {
				data: { ...responseData, statusCode: error.response.status },
			}

			return Promise.reject(errorResponse)
		},
	)

	provide('axios', $axios)
})
