import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'

export default {
	eventRegisterB2B(form) {
		const config = useRuntimeConfig()

		const utm = useCookie(config.public.utm.cookie_name, {
			domain: config.public.utm.cookie_domain,
		})

		let params = {
			platform: 'hire',
			email: form.email,
		}

		if (utm.value) {
			params = { ...params, ...utm.value }
		}

		tracer.track('Registro b2b', params)
		tracer.track('registro-b2b', params)
		tracer.track('register_form_success_loaded', params)
	},
	registerFormClicked() {
		const { previous_url } = $tracking.storage.get('app')
		const { role, country } = $tracking.storage.get('registerFormHire')

		const params = {
			form_role: role || '',
			form_country: country || '',
			previous_url: previous_url || '',
			platform: 'hire',
		}

		tracer.track('register_form_clicked', params)
	},
}
