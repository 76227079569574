import {
	ID_HIRE_FREE,
	ID_HIRE_LITE,
	ID_HIRE_PRO,
	ID_HIRE_TOKEN,
	ID_HIRE_LITE_ZERO,
} from '~/utils/constants/typeSubscription'
import { useSupportStore } from '~/store/support'

export const useSubscriptionStore = defineStore('subscription', {
	state: () => ({
		subscription: null,
		products: [],
	}),
	getters: {
		getCurrentProduct: (state) => {
			const supportStore = useSupportStore()
			return supportStore.getResource('subscriptions').find((item) => item.id === state.subscription.id)
		},
		isSubscriptionFree: (state) => {
			return state.subscription.id === ID_HIRE_FREE
		},
		isSubscriptionLite: (state) => {
			return state.subscription.id === ID_HIRE_LITE || state.subscription.id === ID_HIRE_LITE_ZERO
		},
		isSubscriptionPro: (state) => {
			return state.subscription.id === ID_HIRE_PRO || state.subscription.id === ID_HIRE_TOKEN
		},
		isSubscriptionToken: (state) => {
			return state.subscription.id === ID_HIRE_TOKEN
		},
		isPendingCancel: (state) => {
			return state.subscription?.has_cancel_pending || false
		},
	},
	actions: {
		async fetchCurrentSubscription() {
			const { $repository, $auth } = useNuxtApp()
			const response = await $repository.v4.hire.businesses.getCurrentPlan($auth.user.business.id)

			if (response.success) {
				this.subscription = response.result
			} else if (response.statusCode === 401) {
				await useRouter().push('/logout')
			}

			return response
		},
		async fetchProducts() {
			const { $repository } = useNuxtApp()
			const response = await $repository.v4.hire.getPublicServices()

			if (response.success) {
				this.products = response.result
			}

			return response
		},
	},
})
