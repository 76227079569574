import tracer from '~/tracking/provider'
import $tracking from '~/tracking/utils/storage'
import { AVAILABLE_TO_INTERVIEWS } from '~/utils/constants/matchUserAvailable.js'

const paramsCandidate = (candidate) => {
	return {
		id: candidate?.match_user_id || candidate?.id || '',
		developer_types: candidate?.developer_types || candidate?.roles,
		stacks: candidate?.stacks || '',
		country: candidate?.residency_country || '',
		min_salary: candidate?.min_salary || candidate?.salary?.min || '',
		max_salary: candidate?.max_salary || candidate?.salary?.max || '',
		money_type: candidate?.money_type || candidate?.salary?.money_type || '',
		english_proficiency: candidate?.english_proficiency_data || candidate?.english_proficiency || '',
		work_modalities: candidate?.work_modalities || candidate?.work_modality,
		work_schedules: candidate?.work_schedules || '',
	}
}

const candidateIsAvailable = (candidate) => {
	return candidate.available?.id === AVAILABLE_TO_INTERVIEWS
}

const candidateIsOutstanding = (candidate) => {
	return candidate.index <= 3
}

const candidateAffinity = (candidate) => {
	if (candidate?.affinity) return candidate.affinity

	const { candidates } = $tracking.storage.get('searchTools')
	if (candidates) {
		const candidateFound = candidates.find(function (ele) {
			return ele.id === candidate.id
		})

		return candidateFound ? candidateFound.affinity : null
	}
}

export default {
	talentPreviewClicked(candidate, outstanding) {
		const { talent_position_page, talent_position_search } = $tracking.storage.get('candidateList')
		const { filters } = $tracking.storage.get('searchTools')

		const params = {
			affinity: candidateAffinity(candidate),
			filters,
			outstanding_candidate: outstanding,
			talent_position_page,
			talent_data: paramsCandidate(candidate),
			available_talent: candidateIsAvailable(candidate),
			talent_position_search,
		}

		tracer.track('talent_preview_clicked', params)
	},

	talentPreviewLoaded(candidate) {
		const { filters } = $tracking.storage.get('searchTools')
		const { talent_position_page, talent_position_search, view_type } = $tracking.storage.get('candidateList')

		if (view_type !== 'search_tool') return

		const params = {
			affinity: candidateAffinity(candidate),
			outstanding_talent: candidateIsOutstanding(candidate),
			available_talent: candidateIsAvailable(candidate),
			filters,
			talent_position_page,
			talent_data: paramsCandidate(candidate),
			talent_position_search,
		}

		tracer.track('talent_preview_loaded', params)
	},

	talentPreviewProfileClicked(candidate) {
		const { filters } = $tracking.storage.get('searchTools')
		const { talent_position_page, talent_position_search, view_type } = $tracking.storage.get('candidateList')

		if (view_type !== 'search_tool') return

		const params = {
			affinity: candidateAffinity(candidate),
			outstanding_talent: candidateIsOutstanding(candidate),
			available_talent: candidateIsAvailable(candidate),
			filters,
			talent_position_page,
			talent_data: paramsCandidate(candidate),
			talent_position_search,
		}

		tracer.track('talent_preview_profile_clicked', params)
	},

	talentPreviewCreateOfferClicked(candidate, location, view) {
		const { filters } = $tracking.storage.get('searchTools')
		const { talent_position_page, talent_position_search } = $tracking.storage.get('candidateList')

		const params = {
			button_name: location,
			available_talent: candidateIsAvailable(candidate),
			talent_data: paramsCandidate(candidate),
			view,
			url: window.location.href,
		}

		// Cuando viene de panel, es por el buscador de talents
		if (view === 'panel') {
			params.outstanding_talent = candidateIsOutstanding(candidate)
			params.filters = filters
			params.affinity = candidateAffinity(candidate)
			params.talent_position_page = talent_position_page
			params.talent_position_search = talent_position_search
		}

		tracer.track('talent_preview_create_offer_clicked', params)
	},

	talentPreviewAssignToOfferClicked(candidate, view) {
		const { filters } = $tracking.storage.get('searchTools')
		const { talent_position_page, talent_position_search } = $tracking.storage.get('candidateList')

		const params = {
			available_talent: candidateIsAvailable(candidate),
			talent_data: paramsCandidate(candidate),
			view,
			url: window.location.href,
		}

		// Cuando viene de panel, es por el buscador de talents
		if (view === 'panel') {
			params.outstanding_talent = candidateIsOutstanding(candidate)
			params.affinity = candidateAffinity(candidate)
			params.talent_position_page = talent_position_page
			params.filters = filters
			params.talent_position_search = talent_position_search
		}

		tracer.track('talent_preview_assign_to_offer_clicked', params)
	},
}
