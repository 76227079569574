/*
Este middleware se encarga de los links mágicos, urls con token para autologin.
*/

import type { LocationQueryValue } from 'vue-router'
import tracer from '~/tracking/provider'

async function checkUniqueToken(token: string | LocationQueryValue[], query) {
	const { $repository, $tracking, $localePath, $auth } = useNuxtApp()

	let response = null

	if (query.email) {
		response = await $repository.v3.hire.auth.postValidateEmail({
			token,
		})
	} else {
		response = await $repository.v3.hire.auth.postValidateToken({
			token,
		})
	}

	if (!response.success) {
		$auth.REMOVE_TOKEN()
		return navigateTo($localePath('/login'))
	}

	$auth.ADD_TOKEN(response.result.access_token)
	await $auth.fetchMe()
	$tracking.user.validationMailClick()
}

export default defineNuxtRouteMiddleware(async (to) => {
	const { path, query } = to
	const { $localePath, $auth } = useNuxtApp()
	const token = query.token || query.gate

	if (!token) return

	// Reseteo de la sesión de rastreo de la sesión actual
	tracer.reset()

	// Inicio sesión por medio de token de ingreso
	if (path === '/auth/callback') {
		$auth.ADD_TOKEN(token)

		const newQuery = { ...query }
		delete newQuery.token
		delete newQuery.gate

		return navigateTo($localePath({ path: '/', query: newQuery }))
	}

	// Inicio sesión por medio de token único
	await checkUniqueToken(token, query)
})
