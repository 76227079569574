import { useRequest } from '~/repositories'

export const getPublicServices = () => {
	return useRequest('get', '/v4/hire/public-services')
}

export const getActiveOffers = () => {
	return useRequest('get', '/v4/hire/offers')
}

export const getFiltersSearch = (params) => {
	return useRequest('get', '/v4/hire/search-filters', { params })
}

export default {
	getPublicServices,
	getActiveOffers,
	getFiltersSearch,
}
