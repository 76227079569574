<script setup lang="ts">
import { useScheduleDemo } from '~/composables/useCalendly'

const route = useRoute()
const { urlCalendly, settingCalendly, goToScheduled, calendlyFullLoad } = useScheduleDemo()
// const config = useRuntimeConfig()

const openCalendlyCommand = ref(null)

const getParamsScheduleOffer = computed(() => {
	// const utm = useCookie(config.public.utm.cookie_name, {
	// 	domain: config.public.utm.cookie_domain,
	// })

	return settingCalendly.value()
})

const showCalendly = () => {
	nextTick(() => {
		openCalendlyCommand.value?.click()
	})
}
</script>

<template>
	<Calendly
		v-if="route.query.command === 'schedule_offer'"
		type="popup"
		:url-calendar="urlCalendly"
		:params="getParamsScheduleOffer"
		:close-popup-after-scheduled="true"
		@event-viewed="calendlyFullLoad"
		@scheduled="goToScheduled"
		@script-loaded="showCalendly"
	>
		<button ref="openCalendlyCommand" />
	</Calendly>
</template>

<style scoped></style>
