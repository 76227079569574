/*
Este middleware está destinado para ejecutar scripts u obtener data esencial para el funcionamiento de la aplicación.
*/

export default defineNuxtRouteMiddleware(async () => {
	const { $feature } = useNuxtApp()

	// Si no esta inicializado los feature flags
	if (!$feature.ready) {
		await $feature.loadFeatures({ autoRefresh: true })
		return
	}

	// Refrescar los feature flags
	await $feature.refreshFeatures()
})
